import dayjs from 'dayjs';

export const getTimeToUnix = (time: number | string): number => {
  if (time !== 0) {
    return dayjs(time.toString()).unix();
  }
  return 0;
};

export const relDateFormat = (num: number): string => {
  if (num) {
    return dayjs(dayjs.unix(num)).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
}; // 时间戳格式化
export const clearHtml = (msg: string): string => msg.replace(/<\/?[^>]*>/g, '');
