<template id="PostAudit">
  <!-- vue实例外创建 -->
  <div>
    <div class="PostAuditContainer">
      <div class="searchPart" v-if='data.roleId === 1'>
        <input id="check" type="checkbox" />
        <label for="check" class="check-in" @click='showMenu'>收起<i class="el-icon-arrow-up"></i></label>
        <label for="check" class="check-out" @click='showMenu'>展开<i class="el-icon-arrow-down"></i></label>
        <div class="formPart element">
          <el-form label-position="top" label-width="80px" class="postForm" :model="data.reviewSearchInfo">
            <el-form-item label="帖子ID"
                          prop="postId"
                          :rules="[
                 { type: 'number', message: '帖子ID为数字值'}
              ]"
            >
              <el-input placeholder="请输入" v-model.number="data.reviewSearchInfo.postId"
              ></el-input>
            </el-form-item>
            <el-form-item label="发表者昵称">
              <el-input placeholder="请输入" v-model="data.reviewSearchInfo.createNickName"></el-input>
            </el-form-item>
            <el-form-item label="发表者QQ"
               prop="createQQ"
            >
              <el-input
              type="createQQ"
              placeholder="请输入" v-model="data.reviewSearchInfo.createQQ"
              @input="mastNumber('createQQ')"></el-input>
            </el-form-item>
            <el-form-item label="发表者微信" v-if='data.close'>
              <el-input placeholder="请输入" v-model="data.reviewSearchInfo.createWx"></el-input>
            </el-form-item>
            <el-form-item label="所属社区" v-if='data.close'>
              <el-input placeholder="请输入" v-model="data.reviewSearchInfo.communityName"></el-input>
            </el-form-item>
            <el-form-item label="帖子类型" v-if='data.close'>
              <el-select placeholder="请选择" v-model="data.reviewSearchInfo.postType">
                <el-option v-for='(item, index) in data.selectOption2'
                           :key='index'
                           :label='item.label'
                           :value='item.value'
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发表时间" v-if='data.close'>
              <el-date-picker
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                :default-time="[
                  new Date(2000, 1, 1, 0, 0, 0),
                  new Date(2000, 2, 1, 23, 59, 59)
                ]"
                v-model="data.sendDate"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="帖子标签" v-if='data.close'>
              <el-select placeholder="请选择" v-model="data.reviewSearchInfo.tagName">
                <el-option v-for='(item, index) in data.selectOption'
                           :key='index'
                           :value='item.value' :label='item.label'>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主贴ID" v-if='data.close'
              prop="mainPostId"
              :rules="[
                 { type: 'number', message: '主贴ID为数字值'}
              ]"
            >
              <el-input
              placeholder="请输入主贴ID" v-model.number="data.reviewSearchInfo.mainPostId" type="mainPostId"></el-input>
            </el-form-item>
            <el-form-item label="审核状态" v-if='data.close'>
              <el-select placeholder="请选择" v-model="data.reviewSearchInfo.verifyStatus">
                <el-option v-for='(item, index) in data.selectOption3'
                           :key='index'
                           :label='item.label'
                           :value='item.value'
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主贴回帖" v-if='data.close'>
              <el-select placeholder="请选择帖子标签" v-model="data.reviewSearchInfo.postOpType">
                <el-option v-for='(item, index) in data.selectOption1'
                           :key='index'
                           :value='item.value' :label='item.label'>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="check" @click='reviewSearch'>检索</el-button>
              <el-button class="cancel" @click="clearInfoFn">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="tablePart">
        <div class="tableBox">
          <el-table
            :data="data.tableList"
            style="width: 100%"
            :header-cell-style="{'text-align':'center'}"
          >
            <el-table-column
              prop="postId"
              label="帖子ID"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="communityName"
              label="所属社区"
              align="center"
            >
              <template v-slot="scope">
                <div>
                  {{scope.row.communityName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="postTitle"
              label="帖子标题"
              align="center"
              min-width="100"
            >
              <template v-slot="scope">
                <div @click="openView(scope.row)">
                  <el-tooltip
                    :content="isBackPost(scope.row.parentPostId, scope.row.postTitle, scope.row.postContent)"
                    placement="bottom"
                    effect="light"
                    v-if="isBackPost(scope.row.parentPostId, scope.row.postTitle, scope.row.postContent).length > 18">
                    <div class="replyTable-postContent-container">
                      <span style="color: #4D74FF;cursor:pointer;">
                        <span v-if='scope.row.parentPostId' style='color: #F56C6C'>[回帖]</span>
                          {{ HtmlFilter(
                            isBackPost(scope.row.parentPostId, scope.row.postTitle, scope.row.postContent)
                          )}}
                        </span>
                    </div>
                  </el-tooltip>
                  <div v-else>
                    <div class="replyTable-postContent-container">
                      <span  style="color: #4D74FF;cursor:pointer;">
                        <span v-if='scope.row.parentPostId' style='color: #F56C6C'>[回帖]</span>
                          {{HtmlFilter(isBackPost(scope.row.parentPostId, scope.row.postTitle, scope.row.postContent))}}
                        </span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createNickName"
              label="发表者"
              align="center"
              min-width="100"
            >
              <template v-slot="scope">
                <div class='imgNameBox'>
                  <div class='img'>
                    <img
                      class="table-td-thumb"
                      :src="scope.row.icon"
                      alt="缩略图"
                    />
                  </div>
                  <span @click='openUserInfo(scope.row)'
                  style='cursor:pointer;color: #4D74FF'>{{scope.row.createNickName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTimestamp"
              label="发表时间"
              align="center"
            >
              <template #default="scope">
                <div>
                  <span>{{relDateFormat(scope.row.createTimestamp)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="审核状态"
              align="center"
            >
              <template v-slot='scope'>
                {{changeStatus(scope.row.verifyStatus)}}
              </template>
            </el-table-column>
            <el-table-column
              prop="verifyNickName"
              label="审核人"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="verifyTimes"
              label="审核时间"
              align="center"
            >
              <template #default="scope">
                <div>
                  <span>{{relDateFormat(scope.row.verifyTimestamp)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="tagName"
              label="内容标签"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop=""
              label="操作"
              align="center"
              min-width="140"
            >
              <template v-slot='scope' style='position:relative;'>
                <div style="display:inline-block; margin-right: 20px">
                  <el-button
                    style='text-align: left;display: block;
                    margin-right: 10px;padding: 0;min-height: 24px;color: #67C23A'
                    type="text"
                    :disabled="scope.row.verifyStatus !== 3"
                    :class="scope.row.verifyStatus !== 3 ? 'noPass' : ''"
                    @click="handelReviewPost(5, scope.row)"
                  >通过审核</el-button>

                  <el-button
                    style='text-align: left;
                    margin-left:0px;margin-right: 10px;padding: 0;min-height: 24px;color: #F56C6C'
                    type="text"
                    :disabled="scope.row.verifyStatus !== 3"
                    :class="scope.row.verifyStatus !== 3 ? 'noPass' : ''"
                    @click="handelReviewPost(6, scope.row)"
                  >未通过</el-button>
                </div>

                <el-button
                  style='display: block;
                  margin: 0;
                  padding: 0;
                  min-height: 24px;
                  position: absolute;
                  top: 12px;right: 30px;
                  text-align: left;'
                  type="text"
                  @click="handleEdit(scope.row)"
                >编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="reply-pagination">
          <el-pagination
            style="float:right;padding-top:10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageInfo.pageSize"
            layout="sizes, prev, pager, next"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
      <div class="dialogPart">
        <el-dialog
          :title="reviewUserInfo.title"
          v-model="reviewUserInfo.userInfo"
          destroy-on-close
          :width="reviewUserInfo.width"
          :top="reviewUserInfo.top"
          @open='show'
          :close-on-click-modal=false
          center>
          <div class='userinforBox' v-if='!reviewUserInfo.editorBox && reviewUserInfo.userBox'>
            <div class='info clearfix'>
              <div class='title'>
                <span>昵称: </span>
              </div>
              <span class='text'>{{reviewUserInfo.user.nikckName}}</span></div>
            <div class='info clearfix'>
              <div class='title'>
                <span>QQ: </span>
              </div><span class='text'>{{reviewUserInfo.user.qqNumber}}</span></div>
            <div class='info clearfix'>
              <div class='title'>
                <span>微信: </span>
              </div>
              <span class='text'>{{reviewUserInfo.user.weChat}}</span>
            </div>
            <div class='info clearfix'>
              <div class='title'>
                <span>用户头像: </span>
              </div>
              <div class='userImg'><img :src='reviewUserInfo.user.userImg' alt=''></div>
            </div>
          </div>
          <div class='editorBox' v-if='reviewUserInfo.editorBox'>
            <el-form label-position="right" label-width="80px" class="editorForm" :model="data.aduitFormData">
              <el-form-item label="根贴ID："
                prop="parentPostId"
              >
                <el-input
                type="parentPostId"
                v-model.number="data.aduitFormData.parentPostId" placeholder="" disabled></el-input>
              </el-form-item>
              <el-form-item label="标签：">
                <el-select
                  v-model="data.aduitFormData.tagId"
                  placeholder="请选择"
                  :disabled='data.aduitFormData.parentPostId ? true : false'>
                  <el-option v-for='(item, index) in data.selectOption5'
                             :value='item.value' :label='item.label'
                             :key='index'>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="标题：">
                <el-input v-model="data.aduitFormData.title"
                  placeholder="若填写根贴ID，此项不填"
                 :disabled='data.aduitFormData.parentPostId ? true : false'
                 ></el-input>
              </el-form-item>
              <el-form-item label="类型：">
                <el-select v-model="data.aduitFormData.postType" placeholder="请选择"
                :disabled='data.aduitFormData.parentPostId ? true : false'>
                  <el-option v-for='(item, index) in data.selectOption4'
                             :key='index'
                             :value='item.value'
                             :label='item.label'
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="正文：">
              </el-form-item>
            </el-form>
            <input
              type="file"
              title=""
              name="uploads"
              id="uploads"
              accept="image/png, image/jpeg, image/jpg"
              multiple
              ref="dowloadImg"
              @change="uploadimg($event)"
              style='position: absolute;
              width: 54px;
              height: 42px;
              opacity: 0;
              z-index: 10011;
              '
            />
            <div id='div11'></div>
          </div>
          <div v-if='reviewUserInfo.viewBox' class='viewBox'>
            <IframTem></IframTem>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button
                v-if='!reviewUserInfo.viewBox'
                type="primary" size="small" @click="centerDialogVisible">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<!-- 调用   <mycom></mycom> -->
<script>
import { defineComponent, reactive, onMounted, provide, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { upData } from '@/reactivity/post';
import { getTimeToUnix, relDateFormat } from '@/utils/getTimeToUnix';
// import { getPostVerifyList, getUserInfor, editorPost, reviewPost } from '@/reactivity/ReviewPost';
import { getPostVerifyList, editorPost, reviewPost } from '@/reactivity/ReviewPost';
// import { ElMessageBox, ElMessage } from 'element-plus';
import IframTem from '../common/IframTem';
import E from 'wangeditor';
import { useStore } from 'vuex';
import { getImageSize } from '@/utils/common/index';
export default defineComponent({
  components: {
    IframTem,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await getTableData(pageInfo);
    });


    // 定义请求数据-------------------------------------------------------------------------
    const data = reactive({ // 页面数据
      tableList: [],
      sendDate: [],
      close: true,
      needOpenId: 0,
      selectOption: [
        { label: '全部', value: '' },
        { label: '综合', value: '综合' },
        { label: '问答', value: '问答' },
        { label: '赛事', value: '赛事' },
        { label: '组队交友', value: '组队交友' },
        { label: '活动', value: '活动' },
        { label: '吐槽', value: '吐槽' },
        { label: '攻略', value: '攻略' },
        { label: '问题反馈', value: '问题反馈' },
      ],
      selectOption5: [
        { label: '全部', value: 0 },
        { label: '综合', value: 1 },
        { label: '问答', value: 2 },
        { label: '赛事', value: 3 },
        { label: '组队交友', value: 4 },
        { label: '活动', value: 5 },
        { label: '吐槽', value: 6 },
        { label: '攻略', value: 7 },
        { label: '问题反馈', value: 8 },
      ],
      selectOption2: [
        { value: 0, label: '全部' },
        { value: 1, label: '普通' },
        { value: 2, label: '精华' },
        { value: 3, label: '官方' },
        { value: 4, label: '官方&精华' },
      ],
      selectOption4: [
        { value: 0, label: '普通' },
        { value: 1, label: '官方' },
        { value: 2, label: '精华' },
        { value: 3, label: '官方&精华' },
      ],
      selectOption3: [
        { value: 0, label: '全部' },
        // { value: 1, label: '机审中' },
        { value: 2, label: '机审未通过' },
        { value: 3, label: '未审核' },
        { value: 4, label: '审核通过' },
        { value: 5, label: '审核未通过' },
        { value: 6, label: '已删除' },
      ],
      selectOption1: [
        { value: 1, label: '主贴' },
        { value: 2, label: '回帖' },
      ],
      reviewSearchInfo: {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        postType: 0, // 主贴/回帖
        tagName: '', // 帖子标签
        mainPostId: '', // 主贴ID
        verifyStatus: 3, // 审核状态
        postOpTyp: null,
        // pageNum: pageInfo.pageNum,
        // pageSize: pageInfo.pageSize,
      },
      aduitFormData: {
        postId: '',
        title: '',
        content: '',
        tagId: '',
        postType: '',
        parentPostId: '',
        postCategory: '',
        coverUrl: '',
        coverType: '',
        desc: '',
        roleId: null,
        postOpType: null,
        materialList: [],
      },
    });
      // 定义翻页-------------------------------------------------------------------------
    const pageInfo = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 1,
      verifyStatus: 3,
    });
    provide('needIframeData', data);
    const handelReviewPost = (num, row) => { // 审核操作
      console.log(num, row.postId);
      const req = {
        manageType: num,
        postId: row.postId,
        reason: '',
      };
      reviewPost(req).then((res) => {
        if (res && !res.data.ret) {
          ElMessage.success({
            message: '审核成功',
            type: 'success',
          });
          getTableData({ ...data.reviewSearchInfo, ...pageInfo });
        } else {
          ElMessage.error({
            message: '审核失败',
            type: 'error',
          });
        }
      });
    };
    // 页面加载请求数据-------------------------------------------------------------------------
    const getTableData = (req) => {
      // 后台要求qq类型为字符串,前台要求类型为数字校验,综合考虑,深拷贝处理数据,传值请求;
      const sendReq = JSON.parse(JSON.stringify(req));
      Object.keys(sendReq).forEach((k) => { // 处理搜索入参
        if (!sendReq[k] && k !== 'verifyStatus' && k !== 'postType' && k !== 'tagName') {
          delete sendReq[k];
        } else {
          if (k !== 'createWx'
            && k !==  'createNickName'
            && k !==  'communityName'
            && k !== 'sendDate'
            && k !== 'tagName') {
            req[k] = Number(sendReq[k]);
          }
          if (k ===  'total') {
            delete sendReq[k];
          }
          if (k ===  'createQQ') {
            sendReq[k] = sendReq[k].toString();
          }
        }
      });
      console.log(data.sendDate);
      if (data.sendDate) {
        if (data.sendDate.length > 0) {
          Object.assign(sendReq, {
            createBeginTimestamp: getTimeToUnix(data.sendDate[0]),
            createEndTimestamp: getTimeToUnix(data.sendDate[1]),
          });
        }
      } else {
        delete sendReq.createBeginTimestamp;
        delete sendReq.createEndTimestamp;
      }
      getPostVerifyList(sendReq).then((res) => {
        try {
          if (!res.data.ret && res.data.data) {
            const { roleId } = store.getters.userInfo;
            data.roleId = roleId;
            data.tableList = res.data.data.postVerifyData || [];
            const { total } = res.data.data.pageInfo;
            // pageInfo.pageSize = pageSize;
            // pageInfo.pageNum = pageNum;
            pageInfo.total = total;
          }
        } catch (error) {
          console.error(error);
        }
      });
    };
    // 是否是回帖
    const isBackPost = (parentId, title, content) => {
      if (parentId) {
        return content.replace(/<\/?[^>]*>/g, '');
      }
      return title.replace(/<\/?[^>]*>/g, '');
    };
    // 处理审核状态方法
    const changeStatus = (parmas) => {
      let text = '';
      switch (parmas) {
        case 0:
          text = '全部';
          break;
        case 1:
          text = '机审中';
          break;
        case 2:
          text = '机审未通过';
          break;
        case 3:
          text = '未审核';
          break;
        case 4:
          text = '审核通过';
          break;
        case 5:
          text = '审核未通过';
          break;
        case 6:
          text = '已删除';
          break;
        default:
          text = '状态有误';
          break;
      }
      return text;
    };

    const mastNumber = (key) => {
      console.log(typeof key);
      Object.keys(data.reviewSearchInfo).forEach((k) => {
        if (key === k) {
          data.reviewSearchInfo[key] = (data.reviewSearchInfo[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    // 翻页方法-------------------------------------------------------------------------
    function handleSizeChange(val) {
      pageInfo.pageSize = val;
      pageInfo.pageNum = 1;
      getTableData({ ...data.reviewSearchInfo, ...pageInfo });
    }
    function handleCurrentChange(val) {
      pageInfo.pageNum = val;
      // getPostList(val, 5);
      getTableData({ ...data.reviewSearchInfo, ...pageInfo });
    }

    // 检索方法-------------------------------------------------------------------------
    const reviewSearch = function () {
      pageInfo.verifyStatus = data.reviewSearchInfo.verifyStatus;
      pageInfo.pageNum = 1;
      pageInfo.pageSize = 5;
      getTableData({ ...data.reviewSearchInfo, ...pageInfo });
    };

    // 重置方法-------------------------------------------------------------------------
    const clearInfoFn = () => { // 重置按钮FN
      data.reviewSearchInfo.postId = ''; // 搜索条件回帖ID
      data.reviewSearchInfo.createNickName = ''; // 搜索条件发表者昵称
      data.reviewSearchInfo.createQQ = ''; // 搜索条件发表者QQ
      data.reviewSearchInfo.createWx = ''; // 搜索条件发表者微信
      data.reviewSearchInfo.communityName = ''; // 搜索条件所属社区
      data.reviewSearchInfo.postType = 0; // 帖子类型
      data.reviewSearchInfo.createBeginTimestamp = '';// 发表起始时间戳
      data.reviewSearchInfo.createEndTimestamp = '';// 发表截止时间戳
      data.sendDate = []; // 搜索条件发表时间
      data.reviewSearchInfo.tagName = ''; // 帖子标签
      data.reviewSearchInfo.mainPostId = ''; // 主贴ID
      data.reviewSearchInfo.verifyStatus = 3; // 审核状态
      // 重置页面条数和页码
      pageInfo.pageNum = 1;
      pageInfo.pageSize = 5;
      pageInfo.verifyStatus = 3;
      getTableData({ ...data.reviewSearchInfo, ...pageInfo });
    };

    // 打开用户信息弹框-------------------------------------------------------------------------
    const reviewUserInfo = reactive({
      hot: true,
      noHot: false,
      essence: false,
      userInfo: false,
      editorBox: false,
      userBox: true,
      viewBox: false,
      title: '',
      width: '',
      top: '',
      user: {
        nikckName: '', // 用户昵称
        qqNumber: '', // 用户qq
        weChat: '', // 用户微信
        userImg: '', // 用户头像
      },
    });
    const openUserInfo = (val) => {
      reviewUserInfo.title = '用户信息';
      reviewUserInfo.width = '400px';
      reviewUserInfo.top = '37vh';
      reviewUserInfo.userInfo = !reviewUserInfo.userInfo;
      reviewUserInfo.editorBox = false;
      reviewUserInfo.viewBox = false;
      reviewUserInfo.userBox = true;
      reviewUserInfo.user.nikckName = val.createNickName;
      reviewUserInfo.user.qqNumber = val.loginType === 1 ? val.userId : '/';
      reviewUserInfo.user.weChat = val.loginType === 2 ? val.userId : '/';
      reviewUserInfo.user.userImg = val.icon;
    };
    const changepostType = (isgood, isOfficial) => {
      switch (true) {
        case isgood === 1 && !isOfficial:
          return 2;
        case isOfficial === 1 && !isgood:
          return 1;
        case !isOfficial && !isgood:
          return 0;
        case isOfficial === 1 && isgood === 1:
          return 3;
      }
    };
    // 打开编辑框-------------------------------------------------------------------------
    const handleEdit = (val) => {
      console.log(val);
      reviewUserInfo.userInfo = !reviewUserInfo.userInfo;
      reviewUserInfo.editorBox = true;
      reviewUserInfo.viewBox = false;
      reviewUserInfo.width = '750px';
      reviewUserInfo.title = '帖子详情';
      reviewUserInfo.top = '7vh';
      const scopeData = {
        ...val,
      };
      data.aduitFormData.parentPostId = scopeData.parentPostId ? scopeData.parentPostId : '';
      data.aduitFormData.title = scopeData.parentPostId ? '' : scopeData.postTitle;
      data.aduitFormData.postType = changepostType(scopeData.isGood, scopeData.isOfficial); // 0:普通, 1:官方, 2:精华
      data.aduitFormData.tagId = scopeData.tagId;
      data.aduitFormData.content = scopeData.postContent;
      data.aduitFormData.postId = scopeData.postId;
    };

    const anyTrue = ref(true);
    // 添加富文本
    let editor = reactive({});
    const show = () => {
      setTimeout(() => {
        editor = new E('#div11');
        editor.config.menus = [
          'image',
          // 'video',
          // 'link',
          'italic',
          'bold',
          'foreColor',
          'fontSize',
        ];
        editor.config.onchange = function (newHtml) {
          if (anyTrue.value) {
            anyTrue.value = false;
            if (newHtml.indexOf('<div class="img_wrap"><br/></div>') > -1) {
              const str = newHtml.replace('<div class="img_wrap"><br/></div>', '');
              console.log(str);
              editor.txt.html(str);
            }
          }
          setTimeout(() => {
            anyTrue.value = true;
          }, 500);
        };
        editor.config.onchangeTimeout = 10000;
        editor.create();
        editor.txt.html(`<div>${data.aduitFormData.content}</div>`);
        data.aduitFormData.content = editor.txt.html();
        data.aduitFormData.desc = editor.txt.text();
      }, 50);
    };

    // 定义提取src方法
    const getSrc = async (dataContent) => {
      const re = new RegExp(/<img[^>]*>/gi); // 获取正文中img标签
      const url = new RegExp(/\bsrc\b\s*=\s*['"]?([^'"]*)['"]?/i); // 获取img标签中url地址
      if (dataContent.match(re) !== null) {
        // 正文中有没有图片
        const coverList = dataContent.match(re); // 正文中所有图片标签
        for (const src of coverList) {
          const item = {
            type: 0,
            width: 0,
            height: 0,
            url: '',
            videoId: '',
          };
          const imgUrl = src.match(url)[1];
          item.url = imgUrl;
          try {
            const { width, height } = await getImageSize(imgUrl);
            item.width = width;
            item.height = height;
            data.aduitFormData.materialList.push(item);
            console.log(data.aduitFormData.materialList);
          } catch (e) {
            console.log(e);
          }
        }
      }
    };

    const dowloadImg = ref(null);
    const uploadimg = async (val) => {
      console.log(val);
      const file = val.target.files[0];
      const formData = new window.FormData();
      formData.append('file', file);
      let src = '';
      try {
        const { data } = await upData(formData);
        src = data.data.fileUrl;
        editor.txt.append(`<div class='img_wrap'><img class='add_img' src=${src} alt=''></div>`);
        if (data) {
          console.log(1);
          console.log(dowloadImg.value.value);
          dowloadImg.value.value = '';
        }
      } catch (e) {
        console.log(e);
      }
    };
    // 打开收起菜单
    const showMenu = () => {
      data.close = !data.close;
    };
    const addEditor = (req) => {
      Object.keys(req).forEach((k) => {
        if (k !== 'materialList') {
          if (!req[k]) {
            delete req[k];
          }
        }
      });
      editorPost(req).then((res) => {
        console.log(res);
        if (res.data && !res.data.ret) {
          ElMessage.success({
            message: '编辑成功',
            type: 'success',
          });
          getTableData({ ...data.reviewSearchInfo, ...pageInfo });
        } else {
          ElMessage.error({
            message: '编辑失败',
            type: 'error',
          });
        }
      });
    };

    // 编辑提交功能-------------------------------------------------------------------------
    const centerDialogVisible = async () => {
      if (reviewUserInfo.editorBox) {
        await getSrc(data.aduitFormData.content);
        data.aduitFormData.content = editor.txt.html();
        addEditor(data.aduitFormData);
        reviewUserInfo.userInfo = !reviewUserInfo.userInfo;
      } else {
        reviewUserInfo.userInfo = !reviewUserInfo.userInfo;
      }
      // console.log(editor.txt.html());
    };

    // 打开预览方法----------------------------------------------------------------------
    const openView = (val) => {
      data.needOpenId = val.postId;
      reviewUserInfo.userInfo = !reviewUserInfo.userInfo;
      reviewUserInfo.editorBox = false;
      reviewUserInfo.userBox = false;
      reviewUserInfo.viewBox = true;
      reviewUserInfo.top = '3vh';
      reviewUserInfo.width = '400px';
    };

    // 抽离成可配置的匹配列表
    const matchList  = {
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
      '&#34;': '"',
      '&quot;': '"',
      '&#39;': '\'',
    };
    // 字符过滤器
    const HtmlFilter = (text) => {
      let regStr = `(${Object.keys(matchList).toString()})`;
      regStr = regStr.replace(/,/g, ')|(');
      const regExp = new RegExp(regStr, 'g');
      return text.replace(regExp, match => matchList[match]);
    };


    return {
      data,
      pageInfo,
      handleSizeChange,
      handleCurrentChange,
      reviewSearch,
      clearInfoFn,
      reviewUserInfo,
      openUserInfo,
      handleEdit,
      show,
      openView,
      centerDialogVisible,
      showMenu,
      // getUser,
      changeStatus,
      relDateFormat,
      handelReviewPost, // 操作审核fn
      mastNumber, // 处理纯数字输入
      isBackPost,
      HtmlFilter,
      uploadimg,
      dowloadImg,
    };
  },
});
</script>

<style lang="scss">
@import '../Post/index.scss';
</style>
