
import { request } from '@/services';
// import { onMounted, Ref, ref } from 'vue';
import { AxiosRequestConfig } from 'axios';
import { Request } from '../services/requests/request';
// import { resultInterface } from '../../types/index';

// 请求数据参数
interface IpostAduit {
  postId: number;
  createNickName: string;
  createQQ: number;
  createWx: string;
  communityName: string;
  postOpType: number;
  createBeginTimestamp: number;
  createEndTimestamp: number;
  tagName: string;
  postType: number;
  mainPostId: number;
  verifyStatus: number;
  pageNum: number;
  pageSize: number;
}

// 回包数据类型
interface IpostAduitRetrun {
  root: {
    ret: number;
    msg: string;
    data: {
      postList: {
        postId: number;
        communityId: number;
        postTitle: string;
        createNickName: string;
        icon: string;
        createTimestamp: number;
        verifyStatus: number;
        verifyNickName: string;
        verifyTimestamp: number;
        tagName: string;
        communityName: string;
        loginType: number;
        userId: string;
        uin: string;
      }[];
      pageInfo: {
        pageNum: number;
        pageSize: number;
        total: number;
        totalPage: number;
      };
    };
  };
}

interface resultInterface<T> {
  ret: number;
  msg: string;
  data: T;
  length: number;
}

// 获取审核帖子列表
export function getPostVerifyList(req: IpostAduit): Promise<unknown> {
  return request<AxiosRequestConfig, resultInterface<IpostAduitRetrun>>({
    url: '/api/manage/verify/get_post_verify_list',
    method: 'post',
    data: req,
  });
}


// 获取用户信息接口
interface IgetUserInfo {
  userId: string;
}

interface getUserInfo {
  root: {
    ret: number;
    msg: string;
    data: {
      roleId: number;
      userId: string;
      loginType: number;
      uin: string;
      nickName: string;
    };
  };
}
// 编辑帖子接口
interface addEditorPost {
  postId: number;
  title: string;
  content: string;
  tagId: number;
  postType: string;
  parentPostId: number;
  coverUrl: string;
  coverType: number;
  desc: string;
}
interface addEditorRetrun {
  root: {
    ret: number;
    msg: number;
    data: {

    };
  };
}
interface reviewType {
  manageType: number;
  postId: number;
  reason: string;
}
export function getUserInfor(req: IgetUserInfo): Promise<Request<AxiosRequestConfig, resultInterface<getUserInfo>>> {
  return request<AxiosRequestConfig, resultInterface<getUserInfo>>({
    url: '/api/manage/user/query_role_info',
    method: 'post',
    params: req,

  });
}


export function editorPost(req: addEditorPost): Promise<Request<AxiosRequestConfig, resultInterface<addEditorRetrun>>> {
  return request<AxiosRequestConfig, resultInterface<addEditorRetrun>>({
    url: '/api/manage/post/edit',
    method: 'post',
    data: req,
  });
}
export function reviewPost(req: reviewType): Promise<Request<AxiosRequestConfig, resultInterface<addEditorRetrun>>> {
  return request<AxiosRequestConfig, resultInterface<addEditorRetrun>>({
    url: '/api/manage/post/manage',
    method: 'post',
    data: req,
  });
}
